<template>    
    <vx-card title="Buying Price">
        <div class="vx-row mt-3" style="width: 70%">
            <div class="vx-col w-full mb-base">
                <FilterData :multiWh="true" :multiSp="true" @data="updateFilterData"></FilterData>
            </div>
        </div>
        <div class="vx-input-group flex">
            <vs-tabs :value="activeTab">
                <vs-tab label="Draft">
                    <tab-draft :filterData="filterData"></tab-draft>
                </vs-tab>
                <!-- <vs-tab label="Waiting">
                    <tab-waiting :filterData="filterData"></tab-waiting>
                </vs-tab> -->
                <!-- <vs-tab label="Inquiry">
                    <tab-inquiry :filterData="filterData"></tab-inquiry>
                </vs-tab> -->
                <vs-tab label="Released">
                    <tab-release :filterData="filterData"></tab-release>
                </vs-tab>
                <!-- <vs-tab label="Amended">
                    <tab-log :filterData="filterData"></tab-log>
                </vs-tab> -->
                <vs-tab label="Expired">
                    <tab-expired :filterData="filterData"></tab-expired>
                </vs-tab>
                <!-- <vs-tab label="Rejected">
                    <tab-rejected :filterData="filterData"></tab-rejected>
                </vs-tab> -->
                <vs-tab label="Canceled">
                    <tab-canceled :filterData="filterData"></tab-canceled>
                </vs-tab>
                <vs-tab label="Log Import">
                    <tab-import :filterData="filterData"></tab-import>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabDraft from "./TabDraft.vue";
import TabRelease from "./TabRelease.vue";
import TabWaiting from "./TabWaiting.vue";
import TabInquiry from "./TabInquiry.vue";
import TabLog from "./TabLog.vue";
import TabExpired from "./TabExpired.vue";
import TabRejected from "./TabRejected.vue";
import TabCanceled from "./TabCanceled.vue";
import TabImport from "./TabImport.vue";
import FilterData from "./component/FilterData.vue";
export default {
    components: {
        TabDraft,
        TabRelease,
        TabWaiting,
        TabInquiry,
        TabLog,
        TabExpired,
        TabRejected,
        TabCanceled,
        TabImport,
        FilterData,
    },
    data() {
        return {
            activeTab: sessionStorage.getItem('activeTabDBP') ? sessionStorage.getItem('activeTabDBP') : "0",
            // activeTab: "Draft",
            filterData: {
                warehouseId: [],
                supplierId: [],
                draw: 0
            }
        }
    },
    methods: {
        updateFilterData(val) {
            this.filterData.warehouseId = val.warehouseId;
            this.filterData.supplierId = val.supplierId;
            this.filterData.draw = val.draw;
        },
    },
    mounted(){
        console.log("index ===== ", sessionStorage.getItem('activeTabDBP'))
    }
}
</script>