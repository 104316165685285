<template>
    <div>
        <div class="vx-row mb-1 mt-3" style="width:70%">
            <div class="vx-col w-full mb-base">
                <vs-button class="mr-3" color="primary" @click="handleCreate()">+ Create</vs-button>
                <vs-button class="mr-3" color="primary" @click="handleUpload()">Upload</vs-button>                
                <vs-button class="mr-3" color="grey" @click="handleDownload()">Download Excel</vs-button>
            </div>
        </div>        
        <div class="vx-row mt-1">
            <div class="vx-col w-full mb-base">
                <Table 
                    :draw="draw" 
                    :status="activeTab" 
                    :warehouseId="this.warehouseId"
                    :supplierId="this.supplierId">
                </Table>
            </div>
        </div>

        <vs-prompt
            @cancel="val=''"
            @accept="uploadFile2"
            :active.sync="activePrompt"
            :title="'Upload Form'"
            :accept-text="'Submit'">
            <div class="con-exemple-prompt">
                <label for="supplier" class="font-bold">Supplier</label>
                <Supplier 
                    class="mt-2 mb-6"
                    placeholder="Select supplier / vendor"
                    @selected="(val) => {
                        this.form.supplierId = val.id
                        this.form.supplierName = val.name
                        this.form.supplierCode = val.code
                    }"
                ></Supplier>

                <label for="fileInput" class="font-bold">File to upload</label>
                <input
                    id="fileInput"
                    name="file"
                    class="w-full inputx my-2"
                    type="file"
                    ref="file"
                    accept=".xlsx, .xls"
                    @change="handleChangeFile"
                />                
            </div>
        </vs-prompt>

        <vs-prompt
            @cancel="val=''"
            @accept="downloadFile"
            :active.sync="activePromptDown"
            :title="'Download Excel data for'"
            :accept-text="'Download'">
            <div class="w-full">
                <label for="supplier" class="font-bold">Supplier</label>
                <Supplier 
                    class="mt-2 mb-6"
                    placeholder="Select supplier / vendor"
                    @selected="(val) => {
                        this.form.supplierId = val.id
                        this.form.supplierName = val.name
                    }"
                ></Supplier>

                <!-- <label for="warehouse" class="font-bold">Warehouse</label>
                <Warehouse 
                    class="my-2"
                    placeholder="Select warehouse"
                    @selected="(val) => {
                        this.form.warehouseId = val.id
                        this.form.warehouseName = val.name
                    }"
                ></Warehouse> -->
            </div>
        </vs-prompt>
    </div>
</template>
<script>

import Date from "@/components/Date.vue";
import Table from "./component/Table.vue";
import Currency from "@/components/Currency.vue";
import Warehouse from "@/components/Warehouse.vue";
import Supplier from "@/components/Supplier.vue";

export default {
    components: {
        Date,
        Table,
        Currency,
        Warehouse,
        Supplier,
    },
    props: {
        filterData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            activeTab: "New",
            draw: 0,
            warehouseId: null,
            supplierId: null,
            activePrompt : false,
            activePromptDown : false,
            file : null,
            form: {
                warehouseId : null,
                warehouseName : null,
                supplierId : null,
                supplierName : null,
                supplierCode : null,
            },
        }
    },
    methods: {
        fetchDataWithFilters(filter) {
            this.draw = filter.draw
            this.supplierId = filter.supplierId
            this.warehouseId = filter.warehouseId
        },
        handleCreate(){
            this.$router.push({
                name: this.$store.state.master.buyingPrice.baseRouterName + ".form-create"
            });
        },
        handleDownload(){
            this.activePromptDown = true  
        },
        downloadFile(){
            this.$vs.loading();
            this.$http
            .get(this.$store.state.master.buyingPrice.baseUrlPath + "/excel-create", {
                params: {
                    supplierId: this.form.supplierId
                },
                responseType: "blob",
            })
            .then((resp) => {
                this.$vs.loading.close();
                if (resp.type == "application/json"){
                    this.$vs.notify({
                        title: "Error",
                        text: "Items for supplier "+this.form.supplierName+" not found",
                        // text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x",
                        time: "10000",
                    });
                }else{
                    var fileURL = window.URL.createObjectURL(resp);
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                        "download", "create-buying-price.xlsx"
                    );                
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
        },
        handleUpload() {
            this.activePrompt = true
            this.file = null
        },
        handleChangeFile(event) {
            this.file = event.target.files ? event.target.files[0] : null;
            if (this.file) {
                const reader = new FileReader();
                reader.readAsBinaryString(this.file);
            }
        },
        uploadFile(){
            console.log(this.form.supplierId);
            console.log(this.form.supplierCode);
            console.log(this.form.supplierName);
        },
        uploadFile2() {
            if (!this.file) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                    time: "10000",
                });
                return
            } else if (!this.form.supplierId) {
                this.$vs.notify({
                    title: "Error",
                    text: "Supplier is required.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                    time: "10000",
                });
                return
            }
            
            const allowedExtensions = ["xlsx", "xls"];
            const fileExtension = this.file.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                this.$vs.notify({
                    title: "Error",
                    text: "Invalid file format. Please select an Excel file (XLSX or XLS).",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                    time: "10000",
                });
                return
            }            

            let form = new FormData();
            form.append("file", this.file);
            form.append("supplierId", this.form.supplierId);
            form.append("supplierCode", this.form.supplierCode);
            form.append("supplierName", this.form.supplierName);

            this.$vs.loading();
            this.$http
                .post(this.$store.state.master.buyingPrice.baseUrlPath + "/import", form)
                .then((resp) => {
                    if (resp.code == 200) {
                        setTimeout(() => {
                            this.$vs.notify({
                                title: "Success",
                                text: resp.message,
                                color: "success",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check",
                                time: "5000",
                            });
                            this.draw++
                            this.$vs.loading.close();
                        }, 10000);
                    } else {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: "Error",
                            text: resp.message,
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x",
                            time: "20000",
                        });
                    }                    
                });            
        },
    },
    mounted(){
        this.draw = this.filterData.draw
        this.supplierId = this.filterData.supplierId
        this.warehouseId = this.filterData.warehouseId
        sessionStorage.setItem('activeTabDBP', "0");
    },
    watch: {
        filterData: {
            handler(newVal, oldVal) {                
                this.fetchDataWithFilters(newVal);
            },
            deep: true
        }
    },
}
</script>