<template>
    <div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <TableI 
                    :draw="draw"
                    :warehouseId="this.warehouseId"
                    :supplierId="this.supplierId">
                </TableI>
            </div>
        </div>
    </div>
</template>
<script>

import Date from "@/components/Date.vue";
import TableI from "./component/TableImport.vue";
import Currency from "@/components/Currency.vue";

export default {
    components: {
        Currency,
        Date,
        TableI
    },
    props: {
        filterData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            draw: 0,
            warehouseId: null,
            supplierId: null,
          
            form: {
                warehouseId : null,
                warehouseName : null,
                supplierId : null,
                supplierName : null,
                supplierCode : null,
            },
        }
    },
    methods: {
        fetchDataWithFilters(filter) {
            this.draw = filter.draw
            this.supplierId = filter.supplierId
            this.warehouseId = filter.warehouseId
        },
    },
    mounted(){
        this.draw = this.filterData.draw
        this.supplierId = this.filterData.supplierId
        this.warehouseId = this.filterData.warehouseId
        sessionStorage.setItem('activeTabDBP', "4");
    },
    watch: {
        filterData: {
            handler(newVal, oldVal) {                
                this.fetchDataWithFilters(newVal);
            },
            deep: true
        }
    },
}
</script>