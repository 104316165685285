<template>
    <div>        
        <div class="vx-row mt-1">
            <div class="vx-col w-full mb-base">
                <Table 
                    :draw="draw" 
                    :status="activeTab" 
                    :warehouseId="this.warehouseId"
                    :supplierId="this.supplierId">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import Warehouse from "@/components/Warehouse.vue";
import Date from "@/components/Date.vue";
import Table from "./component/Table.vue";

export default {
    components: {
        Warehouse,
        Date,
        Table,
    },
    props: {
        filterData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            activeTab: "Waiting",
            draw: 0,
            warehouseId: null,
            supplierId: null
        }
    },
    methods: {
        fetchDataWithFilters(filter) {
            this.draw = filter.draw
            this.supplierId = filter.supplierId
            this.warehouseId = filter.warehouseId
        },
    },
    mounted(){
        this.draw = this.filterData.draw
        this.supplierId = this.filterData.supplierId
        this.warehouseId = this.filterData.warehouseId
    },
    watch: {
        filterData: {
            handler(newVal, oldVal) {                
                this.fetchDataWithFilters(newVal);
            },
            deep: true
        }
    },
}
</script>