<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th>File Export</vs-th>
                <vs-th>Supplier</vs-th>
                <vs-th>Warehouse</vs-th>
                <vs-th>Created Document</vs-th>
                <vs-th>Filename</vs-th>
                <vs-th>Created at</vs-th>
                <vs-th>Created by</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Remark</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vx-tooltip text="View">
                                <vs-button class="mr-1" size="small" color="grey" icon-pack="feather" icon="icon-eye" 
                                @click="handleView(tr.id)" />
                            </vx-tooltip>
                            <vx-tooltip text="Download">
                                <vs-button class="mr-1" size="small" color="primary" icon-pack="feather" icon="icon-download" 
                                @click="downloadFile(tr.file_url)" />
                            </vx-tooltip>
                            <vx-tooltip text="Export" v-if="tr.path_export == null">
                                <vs-button class="mr-1" size="small" color="warning" icon-pack="feather" icon="icon-upload" 
                                @click="exportFile(tr, indextr)" />
                            </vx-tooltip>
                        </div>
                    </vs-td>
                    <vs-td v-if="tr.path_export != null && tr.path_export != 'processing'"><a @click.stop="downloadFileAwsS3(tr.path_export)">download</a></vs-td>
                    <vs-td v-else-if="tr.path_export == 'processing'">{{ tr.path_export }}</vs-td>
                    <vs-td v-else>-</vs-td>
                    <vs-td>{{ tr.supplier }}</vs-td>
                    <vs-td>{{ tr.warehouses }}</vs-td>                    
                    <vs-td>
                        <div v-if="tr.data">
                            <div v-for="(doc, index) in JSON.parse(tr.data).created_docs" :key="index">
                                <p>{{ doc }}</p>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.file_name }}</vs-td>
                    <vs-td>{{ formatDate(tr.created_at) }}</vs-td>
                    <vs-td>{{ tr.username }}</vs-td>
                    <vs-td>{{ tr.status }}</vs-td>
                    <vs-td>{{ tr.remark }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        warehouseId: {
            type: Array,
        },
        supplierId: {
            type: Array,
        },
    },
    data() {
        return {
            confirmId: null,
            table: this.tableDefaultState(),
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },                
        getData() {            
            this.$vs.loading();
            this.$http
            .get(this.$store.state.master.buyingPrice.baseUrlPath + "-" + "import-log", {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    warehouse_id: this.warehouseId.slice(),
                    supplier_id: this.supplierId.slice(),
                }
            })
            .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                }
            });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        downloadFile(url) {
            // Create an anchor tag
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank'; // Open file in new tab
            link.download = ''; // Set download attribute to an empty string to enable download
            document.body.appendChild(link);
            link.click(); // Simulate click on the anchor tag
            document.body.removeChild(link); // Remove the anchor tag after clicking
        },
        handleView(id) {
            this.$router.push({
                name: this.$store.state.master.buyingPrice.baseRouterName + ".detail-import",
                params: { id: id },
            });
        },
        exportFile(tr, index) {
            this.$vs.loading();
            this.$http
            .post(this.$store.state.master.buyingPrice.baseUrlPath + "/" + "export", {
                fileName: tr.id + "-" + tr.file_name,
                type: "log-import-buying-price",
                log_import_id: tr.id,
            })
            .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.table.data[index].path_export = "processing"
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            });
        }
    },
    mounted() { },
    watch: {        
        draw() {
            this.getData();
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD H:mm:ss');
            };
        },
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },        
    },
};
</script>
  